import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Overridable from 'react-overridable';
import { Container, Header } from 'semantic-ui-react';

const FooterLayout = (props) => {
  return (
    <Overridable id="Footer.layout">
      <footer>
        <Container fluid className="footer-lower">
          <Container>
            <Header as="h4" textAlign="center">
              <Header.Content>Integrated Library System</Header.Content>
              <Header.Subheader>Powered by INVENIO</Header.Subheader>
            </Header>
          </Container>
        </Container>
      </footer>
    </Overridable>
  );
};

class ILSFooter extends Component {
  render() {
    return <FooterLayout {...this.props} />;
  }
}

ILSFooter.propTypes = {
  staticPages: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      route: PropTypes.string.isRequired,
      apiURL: PropTypes.string.isRequired,
    })
  ),
};

ILSFooter.defaultProps = {
  staticPages: [],
};

FooterLayout.propTypes = ILSFooter.propTypes;
FooterLayout.dafaultProps = ILSFooter.defaultProps;

export default Overridable.component('ILSFooter', ILSFooter);
